import React, { useState } from "react";
import PopUpDialog from "../../../common/Dialog/PopUpDialog";
import labels from "../../../config/labels";
import Box from "../../../common/Box/Box";
import Typography from "../../../common/Typography/Typography";
import { useStyles } from "./index.style";
import Button from "../../../common/Button/Button";
import EntityInputs from "./EntityInputs";
import { entitySchema } from "../../../utils/validators/legalNoticeEntityFormSchema";
import LegalNoticeRequestThankyouModal from "../LegalNoticeRequestThankyouModal";
import Divider from "../../../common/Divider/Divider";
import Spinner from "../../../common/Spinner/Spinner";
import { useLegalNoticeEntities } from "../../../context/LegalNoticeEntities";
import AddCircleOutline from "../../../common/Icons/AddCircleOutline";

const INITIAL_ENTITY_STATE = {
  form: {
    entityName: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phoneNumber: "",
    email: "",
    logo_file: null,
  },
  errors: {
    entityName: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phoneNumber: "",
    email: "",
    logo_file: "",
  },
};

const SubmitLegalNoticeRequestModal = ({ isOpen, onClose }) => {
  const classes = useStyles();

  const [entities, setEntities] = useState([INITIAL_ENTITY_STATE]);
  const [openThankYouModal, setOpenThankYouModal] = useState(false);
  const { fetchLegalNoticesForUser, createLegalNoticeEntities, creatingEntities: loading } = useLegalNoticeEntities();

  const handleChange = (e, index) => {
    const { name, value } =
      e.target.name === "logo_file"
        ? { name: "logo_file", value: e.target.files[0] }
        : e.target;
    const currentEntity = entities[index];
    const updatedEntity = {
      ...currentEntity,
      form: {
        ...currentEntity.form,
        [name]: value,
      },
    };
    const updatedEntities = [...entities];
    updatedEntities[index] = updatedEntity;

    setEntities(updatedEntities);

    updatedEntities.forEach((_entity, i) => {
      if (i === index) {
        validateForm({ [name]: value }, index);
      }
    });
  };

  const handleClose = () => {
    onClose();
    setEntities([INITIAL_ENTITY_STATE]);
  };


  const handleAddEntity = () => {
    setEntities((prev) => {
      return [...prev, INITIAL_ENTITY_STATE];
    });
  };

  const handleSuccess = () => {
    fetchLegalNoticesForUser();
    onClose();
    setEntities([INITIAL_ENTITY_STATE]);
  };

  const handleCloseThankyouModal = () => {
    setOpenThankYouModal(false);
    handleClose();
  };

  const validateForm = (formData, index) => {
    try {
      entitySchema.validateSync(formData, { abortEarly: false });
      setEntities((prev) => {
        const currentEntity = prev[index];
        const updatedEntity = {
          ...currentEntity,
          errors: {
            entityName: "",
            address: "",
            phoneNumber: "",
            email: "",
            zip: "",
            city: "",
            state: "",
          },
        };
        const updatedEntities = [...prev];
        updatedEntities[index] = updatedEntity;
        return updatedEntities;
      });
      return true;
    } catch (err) {
      Object.keys(formData).forEach((key) => {
        const error = err.inner.find((e) => e.path === key);
        setEntities((prev) => {
          const currentEntity = prev[index];
          const updatedEntity = {
            ...currentEntity,
            errors: {
              ...currentEntity.errors,
              [key]: error?.message || "",
            },
          };
          const updatedEntities = [...prev];
          updatedEntities[index] = updatedEntity;
          return updatedEntities;
        });
      });
      return false;
    }
  };

  const handleSubmit = () => {
    let allSuccess = false;
    entities.forEach((entity, index) => {
      allSuccess = validateForm(entity.form, index);
    });

    if (allSuccess) {
      const parsedEntities = entities.map((entityState) => ({
        name: entityState.form.entityName,
        address: entityState.form.address,
        second_address: entityState.form.address2,
        city: entityState.form.city,
        state: entityState.form.state,
        zip: entityState.form.zip,
        phone: entityState.form.phoneNumber,
        email: entityState.form.email,
        logo_file: entityState.form.logo_file,
      }));
      createLegalNoticeEntities(parsedEntities, {
        onSuccess: handleSuccess,
      });
    } else {
      console.log("Form is invalid", entities);
    }
  };

  return (
    <>
      <PopUpDialog
        onClose={handleClose}
        openState={isOpen}
        closeButtonColor="black"
        titleClass={classes.modal_title}
        content={
          <Box className={classes.main_container}>
            <Typography
              level="font_size_32_px"
              className={classes.content_title}
            >
              {labels.SUBMIT_REQUEST_LEGAL_NOTICE}
            </Typography>
            {entities.map((entity, index) => (
              <div key={`${entity.form.phone}-${index}`}>
                <EntityInputs
                  key={index}
                  onHandleChange={(e) => handleChange(e, index)}
                  errors={entity.errors}
                  entity={entity.form}
                />
                {entities.length > 0 && entities.length - 1 !== index && (
                  <Divider variant="middle" component="div" />
                )}
              </div>
            ))}
            <Box className={classes.add_more_entities_button}>
              <Button
                level="simple_button"
                variant="contained"
                startIcon={<AddCircleOutline />}
                onClick={handleAddEntity}
              >
                {labels.ADD_MORE_ENTITIES}
              </Button>
            </Box>
            <Box className={classes.actions_container}>
              <Button
                level="cms_button"
                variant="text"
                classes={{ root: classes.cancel_button }}
                onClick={handleClose}
              >
                {labels.CANCEL_REQUEST}
              </Button>
              <Button
                level="cms_button"
                variant="contained"
                classes={{ root: classes.request_button }}
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? (
                  <Spinner
                    withLoadingOverlay={false}
                    spinnerColor="white"
                    noContainer
                  />
                ) : (
                  labels.SAVE_ENTITY
                )}
              </Button>
            </Box>
          </Box>
        }
      />
      <LegalNoticeRequestThankyouModal
        isOpen={openThankYouModal}
        onClose={handleCloseThankyouModal}
      />
    </>
  );
};

export default SubmitLegalNoticeRequestModal;
