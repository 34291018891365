import React from "react";
import { MuiTelInput } from "mui-tel-input";

const PhoneInput = ({ handleChange, name, error, helperText, value }) => {
  return (
    <MuiTelInput
      defaultCountry="US"
      label="Phone Number"
      value={value}
      fullWidth
      helperText={helperText}
      variant="outlined"
      onChange={(value) => handleChange({ target: { name, value } })}
      name={name}
      error={error}
    />
  );
};

export default PhoneInput;
