import * as yup from "yup";

export const entitySchema = yup.object({
  entityName: yup.string().required(),
  address: yup.string().required(),
  phoneNumber: yup
    .string()
    .required()
    .matches(/^\+?[\d()\- ]{1,16}$/, "Invalid phone number"),
  email: yup.string().required().email(),
  zip: yup
    .string()
    .required()
    .matches(/^[0-9]+$/, "Zip code must be a number"),
  city: yup.string().required(),
  state: yup.string().required(),
});