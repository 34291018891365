import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  loadCustomMasterheadButtons,
  fetchTheme,
} from "../services/townService";

const TownThemeContext = React.createContext({});

const TownThemeContextProvider = ({ children }) => {
  const [townSlug, setTownSlug] = useState();
  const [firstBlockButtons, setFirstBlockButtons] = useState([]);
  const [secondBlockButtons, setSecondBlockButtons] = useState([]);
  const [town, setTown] = useState();
  const [signUpPath, setSignUpPath] = useState("");
  const [themeColors, setThemeColors] = useState();
  const [themeTexts, setThemeTexts] = useState();

  useEffect(() => {
    const loadCustomButtons = async () => {
      const response = await loadCustomMasterheadButtons(townSlug);

      setFirstBlockButtons(response?.data?.first_block_buttons);
      setSecondBlockButtons(response?.data?.second_block_buttons);
      if (town == undefined) setTown(response?.data?.town);
      setSignUpPath(response?.data?.newsletter_signup_path);
    };

    const loadTheme = async (slug) => {
      const response = await fetchTheme(slug);

      if (response?.status === 200) {
        setThemeColors(response?.data?.theme_colors);
        setThemeTexts(response?.data?.theme_texts);
      }
    };

    if (townSlug) {
      loadCustomButtons();
      loadTheme(townSlug);
    }

    return () => {
      setFirstBlockButtons([]);
      setSecondBlockButtons([]);
      setTown();
      setSignUpPath();
      setThemeColors();
    };
  }, [townSlug]);

  return (
    <TownThemeContext.Provider
      value={{
        setTownSlug,
        firstBlockButtons,
        secondBlockButtons,
        town,
        signUpPath,
        themeColors,
        themeTexts,
      }}
    >
      {children}
    </TownThemeContext.Provider>
  );
};

TownThemeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useTownTheme = () => {
  const TownThemeContextValues = useContext(TownThemeContext);

  if (!TownThemeContextValues) {
    throw new Error(
      "useContext must be used within a descendant of the TownThemeContextProvider"
    );
  }

  return TownThemeContextValues;
};

const withTownThemeContextProvider = (Component) => {
  const ComponentWithProvider = (props) => (
    <TownThemeContextProvider>
      <Component {...props} />
    </TownThemeContextProvider>
  );

  return ComponentWithProvider;
};

export { useTownTheme, withTownThemeContextProvider };
