import restClient from "../utils/restClient";
import withConfig from "../utils/withConfig";

export const getTopStories = async ({ town_id = null, limit = 5 }) => {
  try {
    const response = await restClient.get(
      withConfig(
        town_id ? "GET_TOP_LOCAL_NEWS_BY_TOWN" : "GET_OVERALL_TOP_LOCAL_NEWS"
      ),
      {
        town_id: town_id,
        top_stories_limit: limit,
      }
    );
    return response;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getDisplayBlocks = async (town_id, side = null) => {
  try {
    const response = await restClient.get(
      withConfig("GET_DISPLAY_BLOCKS_BY_TOWN"),
      {
        town_id: town_id,
        side,
      }
    );
    return response;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getSponsors = async (town_id) => {
  try {
    const response = await restClient.get(withConfig("GET_SPONSORS_BY_TOWN"), {
      town_mongo_id: town_id,
    });
    return response;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getVideoAd = async (town_id) => {
  try {
    const response = await restClient.get(withConfig("GET_VIDEO_AD"), {
      town_id: town_id,
      controller_to_render: "towns",
      action_to_render: "show",
    });
    return response;
  } catch (e) {
    console.log(e);
    return false;
  }
};
