import React, { useState } from "react";
import { fetchLegalNoticeEntities } from "../services/users";
import { createLegalNoticieEntitiesFn } from "../services/legalNoticeEntities";

const legalNoticeEntitiesContext = React.createContext();

const LegalNoticeEntitiesProvider = ({ children }) => {
  const [userIdForEntities, setUserIdForEntities] = useState(null);
  const [selectedLegalEntityId, setSelectedLegalEntityId] = useState("");
  const [legalNoticesEntities, setLegalNoticesEntities] = useState([]);
  const [creatingEntities, setCreatingEntity] = useState(false);
  const [usStatesForDropdown, setUsStatesForDropdown] = useState([]);
  const [error, setError] = useState(null);

  const fetchLegalNoticesForUser = async () => {
    const response = await fetchLegalNoticeEntities(userIdForEntities);
    if (response?.status === 200) {
      setLegalNoticesEntities(response?.data);
      setSelectedLegalEntityId(response?.data[0]?.id || "");
    }
  };

  const createLegalNoticeEntities = async (entities, { onSuccess }) => {
    setCreatingEntity(true);
    try {
      await createLegalNoticieEntitiesFn({ entities, userId: userIdForEntities });
    } catch (error) {
      setError(error?.message || "An error occurred");
    } finally {
      setCreatingEntity(false);
      onSuccess?.();
    }
  };

  return (
    <legalNoticeEntitiesContext.Provider
      value={{
        creatingEntities,
        error,
        createLegalNoticeEntities,
        userIdForEntities,
        setUserIdForEntities,
        selectedLegalEntityId,
        setSelectedLegalEntityId,
        legalNoticesEntities,
        fetchLegalNoticesForUser,
        usStatesForDropdown,
        setUsStatesForDropdown,
      }}
    >
      {children}
    </legalNoticeEntitiesContext.Provider>
  );
};

const useLegalNoticeEntities = () => {
  const context = React.useContext(legalNoticeEntitiesContext);
  if (context === undefined) {
    throw new Error(
      "useLegalNoticeEntitiesContext must be used within a LegalNoticeEntitiesProvider"
    );
  }
  return context;
};

const withLegalNoticeEntitiesProvider = (Component) => {
  const ComponentWithProvider = (props) => (
    <LegalNoticeEntitiesProvider>
      <Component {...props} />
    </LegalNoticeEntitiesProvider>
  );

  return ComponentWithProvider;
};

export { useLegalNoticeEntities, withLegalNoticeEntitiesProvider };
